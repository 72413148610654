import { useState, useCallback, useEffect, useReducer, memo } from "react";
import { useUserContext } from "../components/UserContext";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label } from "reactstrap";
import { API_SHIFTS_URL } from "../constants";
import { getToForm } from "../includes/Requests";
import { Spinner } from "./Spinner";
import { eventChange, createEvent, deleteEvent } from "../components/Calendar";
import { /*Multiselect,*/ NumberPicker } from "react-widgets";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}


export const ShiftModal = memo(({modalOpened, openModal, modalEvent, modalPole, benevoles, toggle, getAll}) => {
  const context = useUserContext();
  const [isLoading, stillLoading] = useState(false);
  const [isSubmited, setSubmited] = useState(false);
  const [newEvent, setNewEvent] = useState(true);
  const [formData, setFormData] = useReducer(formReducer, {});
  
  const getShift = useCallback(() => {
    if (modalEvent.id && modalEvent.id !== "undefined") {
      setNewEvent(false);
      stillLoading(true);
      getToForm(setFormData, context, API_SHIFTS_URL + modalEvent.id, {}, stillLoading);
    }
  }, [modalEvent.id, context])

  useEffect(() => {
    getShift();
  }, [getShift, modalEvent])

  const handleSubmit = () => {
    if (newEvent) {
      createEvent(modalEvent, modalPole.nom, modalPole.id, formData, context, setSubmited, getAll);
    } else {
      eventChange(formData, context, getAll);
    }
    openModal(false);
  }

  const handleDeletion = () => {
    deleteEvent(formData, context, getAll);
    openModal(false);
  }
  const fromDataToValue = (benevoles) => benevoles.length > 0 && benevoles.filter((b) => {
      var available = true;
      if(b.date_disponible !== undefined) {
        var dispo = new Date(b.date_disponible);
        modalEvent.start < dispo ? available = false : available = true;
      }
      if(available === true) {
        return true 
      } else {
        return false
      }
  }).map((b) => { 
    return {"value": b.id, "label": `${b.first_name} ${b.last_name} (${b.heures_travaillees_coef}h coef.)`}
  })
  
  const fromValueToData = (value) => value.map((v) => {
      return {"id": v.value};
  })
  
  
  return (
      <Modal
        fullscreen="md"
        isOpen={modalOpened}
        toggle={toggle}
        modalTransition={{ timeout: 0 }}
      >
        <ModalHeader toggle={toggle}>
          {modalEvent.title ? modalEvent.title : "Nouveau shift"}
        </ModalHeader>
        <ModalBody>
            <div style={getShiftProgression(formData)}></div>
            <Spinner isLoading={isLoading}>
              <fieldset>
                <Label>Effectif désiré:
                  <NumberPicker name="effectif" onChange={value => setFormData({name: "effectif", value: value})} value={formData.effectif || 2 }/>
                </Label>
              </fieldset>
              <fieldset>
                <Label
                  style={{minWidth:"260px"}}
                >Bénévoles:
                  <Select 
                  name="benevoles"
                  isMulti isSearchable isClearable={false}
                  components={animatedComponents}
                  defaultValue={fromDataToValue(formData.benevoles || [])}
                  options={fromDataToValue(benevoles)}
                  onChange={value => setFormData({name: "benevoles", value: fromValueToData(value)})}
                   />
                </Label>
              </fieldset>
              <fieldset>
                <Label>Coefficient de pénibilité:
                  <NumberPicker name="coef" step={0.5} onChange={value => setFormData({name: "coef", value: value})} value={formData.coef || 1 }/>
                </Label>
              </fieldset>
            </Spinner>
        </ModalBody>
        <ModalFooter>
          {newEvent ? "" : 
            <Button color="danger" onClick={handleDeletion} style={{marginRight:"auto"}}>Supprimer</Button>
          }
          <Spinner isLoading={isSubmited}>
            <Button color="success" onClick={handleSubmit} >
              {newEvent ? "Ajouter" : "Modifier"}
            </Button>
          </Spinner>
          <Button onClick={toggle}>Annuler</Button>
        </ModalFooter>
      </Modal>
  )
})


const getShiftProgression = (shiftData) => {
  let shiftSize;
  shiftData.benevoles ? shiftSize = shiftData.benevoles.length : shiftSize = 0;
  let percentFull = Math.floor(100 * shiftSize / shiftData.effectif);
  let data = {
    width: "100%",
    height: "3px",
    background: `linear-gradient(to right, green 0 ${percentFull}%, red ${percentFull}% 100%)`,
  }
  return data;
}