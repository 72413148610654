import { useState, useEffect } from "react";
import { get } from "../includes/Requests.js";
import { API_POLES_URL } from "../constants";
import { InfosForm } from "../components/InfosForm";

export default function Registration () {
    const [poles, setPoles] = useState([]);

    useEffect(() => {
        get(setPoles, {}, API_POLES_URL)
    }, []);
    

    return (
    <div className="content" style={{textAlign:"center"}}>
      <span>Les inscriptions sont désormais closes. À bientôt sur le festival !</span>
        <InfosForm context="" poles={poles} isRegistration={true}>
        <h1 className="center">Je n'ai pas de compte, je m'enregistre</h1>
    </InfosForm>
    </div>
    ) 
}
