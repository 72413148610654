import React from 'react';
//import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useUserContext } from "../components/UserContext";
import { CHANGE_PASSWD_URL } from "../constants";
import { InfosForm } from "../components/InfosForm.js";
import { Button } from "reactstrap";

export default function Infos() {
  const context = useUserContext();

  return(
      <InfosForm context={context} poles={context.poles} isRegistration={false}>
      <h1>{context.user.first_name}, tu peux changer tes informations personnelles ici</h1>
      <Button><Link to={CHANGE_PASSWD_URL}>modifier mon mot de passe</Link></Button>
      </InfosForm>
  )
}

/*Infos.propTypes = {
  setToken: PropTypes.func.isRequired
};*/