import { FaSpinner } from 'react-icons/fa';

export function Spinner(props) {
  if (props.isLoading) {
    return (
      <div className="spinner-wrapper">
        <FaSpinner className="spinner"/>
      </div>
    )
  }
  return (
    <>
      {props.children}
    </>
  )
}