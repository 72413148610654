import { version } from "../constants";
import logo from '../includes/logo.png';

export default function Footer() {
    const style={
        height: "100px",
        textAlign: "center",
        display: "block",
    }
    return (
        <div style={style}>
            <hr /> 
            <a href="https://blackwoodstock.nc" target="_blank" rel="noreferrer"><img src={logo} alt="BWSF logo"/> </a>
            <p>v{version}</p>
        </div>
    )
}