import { Container, Row, Col, Card, CardHeader, CardBody, CardText, Button} from "reactstrap";
import { useUserContext } from "../components/UserContext";
import { useEffect, useState } from "react";
import { get } from "../includes/Requests";
import { Link, useParams } from "react-router-dom";
import { Calendar } from "../components/Calendar";
import { CHANGE_INFOS_URL } from "../constants";
import { FaEnvelopeOpen, FaPhone, FaCalendarCheck, FaTshirt, FaHeart, FaLeaf } from 'react-icons/fa';
import { FaPole } from '../pages/Pole';
import { API_USER_URL } from "../constants";
import { LoadingOverlay } from "../components/LoadingOverlay";


export function Profile() {
    const context = useUserContext(); // User actuel
    const { userid } = useParams(); // Si défini, user demandé
    const [user, setUser] = useState(null); // Si défini, user demandé
    const [stillLoading, setStillLoading] = useState(true);

    useEffect(() => {
        if(userid) {
            get(setUser, context, API_USER_URL + userid, {}, setStillLoading);
        } else {
            setUser(context.user);
            setStillLoading(false);
        }
    }, [context, userid])

    return (
    <>
    { context.user.adherent ?
    <>
        { stillLoading 
        ?
        <LoadingOverlay/>
        :
        <Container>
            <Row>
                <Col xs="12" sm="7" md="9">
                    <ProfileInfos context={context} user={user}/>
                </Col>
                <Col xs="12" sm="5" md="3">
                </Col>
            </Row>
            <Row>
                <ProfileShifts context={context} user={user} />
            </Row>
        </Container>
        }
    </>
    : 
      <>
      <p>Il semble que tu n'aies pas pris ton adhésion à l'association Blackwoodstock. Elle te sera nécessaire pour entrer sur le site du Fort Teremba et pour qu'on puisse t'assigner des créneaux de bénévolat.</p>
      <p>Rends toi sur <a href="https://www.eticket.nc/adhesion-association-blackwoodstock-2022" target="_blank" rel="noreferrer">la page Eticket correspondante</a>, et un administrateur te donnera accès sous peu de temps. Idem si tu viens de t'inscrire et que tu as déjà pris ton adhésion.</p>
      <p>N'hésite pas à <a href="mailto:benevoles@blackwoodstock.nc">nous contacter</a> si tu vois toujours ce message après plusieurs jours, ou si plus généralement tu as des questions</p>
      </>
    }
    </>
    )
}

function ProfileInfos({context, user}) {
    let userData;
    isImpersonating(context, user) ? userData = user : userData = context.user;
    let dateDispo = new Date(userData.date_disponible).toLocaleString("fr-FR", {"dateStyle": "full", "timeStyle": "medium"});
    return (
    <>
        <h1>{isImpersonating(context,user) ? `Profil de ${userData.first_name} ${userData.last_name}` : `Bonjour ${userData.first_name} !`} </h1>
        <div className="profile-infos">
            <FaEnvelopeOpen/> Email: {userData.email}<br />
            <FaPhone/> Tel: {userData.telephone}<br />
            <FaTshirt/> T-shirt: {userData.tshirt.toUpperCase()}<br />
            <FaCalendarCheck/> Date dispo: {dateDispo}<br />
            {userData.vegan && <><FaLeaf/> Vegan <br/></> }
            {!isImpersonating(context, user) && <GreetingMylene benevole={user}/>}
        </div>
        {!isImpersonating(context, user) && <Button><Link to={CHANGE_INFOS_URL}>Modifier mes informations</Link></Button>}
    </>
    )
}

function GreetingMylene({benevole}) {
    // Easter egg pour madame Croquet
    if (benevole.email === 'mylenecroquet@gmail.com') {
        return <>
                <FaHeart/> Mylène tu es la meilleure des meilleures <FaHeart/>
               </>
    }
}

function ProfileShifts({context, user}) {
    let userData;
    user ? userData = user : userData = context.user;
    var shifts = userData.shifts.filter((s) => s.pole.nom !== "Autres");
    return (
        <>
        <Card
            body
            inverse
            style={{
                backgroundColor: '#333',
                borderColor: '#333',
                }}
        >
            { userData.shifts && userData.shifts.length > 0 ? 
            <>
                <CardHeader><h2>{isImpersonating(context, user) ? "S" : "M"}es shifts</h2></CardHeader>
                    <p>Pour un total de {userData.heures_travaillees} heures travaillées</p>
                    <Container>
                        <Row xl="3" md="2" xs="1">
                            { shifts.map((s) => (
                                <ListedShift shift={s} key={s.id}/>
                            )) }
                        </Row>
                    </Container>
                <hr />
                <CardHeader><h2>{isImpersonating(context, user) ? "S" : "M"}on agenda</h2></CardHeader>
                    <Calendar editable={false} events={shifts} showEventsUsage={false}/>
            </>
             : 
             user ? <p>Tu n'as pas encore de shift prévu, reviens avant le festival pour en prendre connaissance !</p> : <p>Cet utilisateur n'a pas encore de shift</p> 
            }  
        </Card>
        </>
    )
}


function ListedShift({shift}) {
    let heures = ShiftHeures(shift);
    let day = new Date(shift.debut).toLocaleDateString('fr-FR', {"dateStyle":"medium"});
    let debut = new Date(shift.debut).toLocaleTimeString('fr-FR');
    let fin = new Date(shift.fin).toLocaleTimeString('fr-FR');
    if(shift.pole.nom === "Autres") {
        return ""
    }
    return (
        <Col xl>
        <Card>
            <CardHeader tag="h3">
            
            Pole <FaPole nom={shift.pole.nom}/> {shift.pole.nom}
            </CardHeader>
            <CardBody>
                <CardText>
                    <span>
                        Le {day}<br/>
                        De {debut} à {fin} ({heures}h)<br/>
                        <Chefs chefs={shift.pole.chefs}/>
                        <strong>Avec</strong> {returnComaSeparatedList(shift.benevoles)}
                    </span>
                </CardText>
            </CardBody>
        </Card>
        </Col>
    )
}

function Chefs({chefs}) {
    return (
        <>
        {chefs && chefs.length !== 0 &&
            <span><strong>Chefs de pôle</strong> :  
                {chefs.map((c) => <span key={c.first_name}> {c.first_name} (tel: {c.telephone})<br/></span>
                )}
            </span>
        }
        </>
    )
}

export function ShiftHeures(shift) {
    let debut = new Date(shift.debut);
    let fin = new Date(shift.fin);
    let heures = Math.round((fin.getTime() - debut.getTime())/36000)/100;
    return heures;
}

function isImpersonating(context, user) {
    if (!user || user.id === context.user.id) {
        return false
    }
    return true
}

const returnComaSeparatedList = (array) => array.map((b) => b.first_name + ' ' + b.last_name[0] + '.').join(", ")