import { Container, Row, Col } from "reactstrap";

export default function FAQ() {
    return (
    <Container>
      <Row>
        <Col>
        <>
        <h1>Informations pratiques</h1>
            <p>
            Merci de ton inscription dans la dream-team des bénévoles du Festival !
            </p>
        <h2>Prerequis</h2>
        <div>
            <p>
                Tout bénévole doit s'acquitter de <a href="https://www.eticket.nc/adhesion-association-blackwoodstock-2022">l'adhésion à l'association</a> pour l'année en cours, pour pouvoir entrer sur le site du festival.
            </p>
        </div>
        <div>
        <h2>Fonctionnement</h2>
            <p>
                Chaque bénévole peut travailler entre 3h et 6h par jour de présence sur le festival, pour un total de 12h. On appelle communément "shift" le nom d'un créneau de travail avec bénévoles, et tous ceux qui te sont assignés sont listés sur ta page profil, avec en plus les noms et tel de tes chefs de pôle à contacter en cas de problème pendant le shift. Le reste du temps, le pôle bénévole sera situé sous la grande tente en forme de demi-lune, dans le village festival.
            </p>
        </div>
        <h2>Arrivée et camping</h2>
        <div>
            <p>
            Être bénévole t'offre un accès gratuit au festival mais implique bien évidemment que tu sois présent sur la totalité du temps d'ouverture de festival, ou à minima les créneaux (shifts) qui te seront assignés.
            </p>
            <p>
            Un invitation Eticket t'est envoyé au moins 10 jours avant le festival. À ton arrivée, passe par le point billetterie comme tous les autres festivaliers (ton billet sera scanné ici). Tu peux donc faire le voyage avec des amis festivaliers sans que cela pose de problème. Sache par contre que ta voiture sera inspectée par les gendarmes comme toutes les autres voitures.
            </p>
            <p>
            Dans la foulée, n'attend pas et rejoins le pole infos Blackwoodstock dans le village (sous la grande tente blanche en demi-lune) pour t'annoncer à l'équipe gestion des bénévoles, tu y recevras un ou deux t-shirts et pourras faire le point sur tes shifts (les mêmes que ceux affichés sur la page d'accueil de ce site).
            Côté camping, il existe un camping réservé aux bénévoles, et situé derrière le fort Teremba. Libre à toi d'aller dans le camping festivaliers si tu le préfères bien entendu mais les sanitaires du Fort te permettront d'avoir accès à des toilettes et des douches en "dur". Elles ne sont pas situés dans l'enceinte du fort mais juste derrière.
            </p>
        <h2>Repas</h2>
            <p>
            Un repas bénévoles est prévu pour le samedi midi ET le dimanche midi. Nous te demandons <strong>d'amener tes couverts</strong> dans un souci de réduction des déchets ! :) Si tu es végétarien ou végétalien, nous avons pensé à toi. Assure toi de bien avoir coché la case dans tes informations sur ce site pour en profiter. Tu pourras aussi profiter d'un café à l'espace petits dej'.
            </p>
        <h2>Questions ?</h2>
            <p>
            En cas de questions, tu peux nous les poser à l'adresse email <a href="mailto:benevoles@blackwoodstock.nc">benevoles@blackwoodstock.nc</a>, alors n'hésite pas !
            </p>

        </div>
        </>
        </Col>
      </Row>
    </Container>
    )
}