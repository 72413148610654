import FullCalendar from "@fullcalendar/react"; 
import { useState, memo } from "react";
import { useUserContext } from "../components/UserContext";
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import { FESTIVAL_START, API_SHIFTS_URL } from "../constants";
import { ShiftModal } from "./Modal";
import { patch, post, del } from "../includes/Requests";

export default function CalendarWithModal(props) {
    const [modalOpened, openModal] = useState(false); // État de la modal
    const [modalEvent, setModalEvent] = useState(); // Event de calendar actuellement selectionné pour la modal
    const toggle = () => openModal(!modalOpened);

    return (
      <>
        <Calendar 
          openModal={openModal} setModalEvent={setModalEvent} editable={true} {...props}
        />
        { modalOpened && 
          <ShiftModal modalOpened={modalOpened} openModal={openModal} modalEvent={modalEvent} modalPole={props.pole} benevoles={props.benevoles} toggle={toggle} getAll={props.getAll}/>
        }
      </>
    )
}

export const Calendar = memo(props => {
    const context = useUserContext();
    var editable;
    props.editable && context.user.is_staff ? editable = {
        'editable': true,
        'eventClick': (i) => eventClickOrNew(i.event, props),
        'selectable': true,
        'selectMirror': true,
        'eventChange': (i) => eventChangeAfterAlert(i.event, context),
        'select': (i) => eventClickOrNew(i, props),
    } : editable = {
        'editable': false,
    };
    
    const staticEvents = (events) => {
      return events.map((e) => {
        let eventOpts = getEventOpts(props.editable, e); //[0] is event color, [1] is event title
        return {
          "id": `${e.id}`,
          "start": new Date(e.debut),
          "end": new Date(e.fin),
          "display": "block",
          "backgroundColor": eventOpts[0],
          "title": eventOpts[1],
        }
      })
    }

    var events;
    props.events ? events = staticEvents(props.events) : events = [];

    const options =  {
        'themeSystem': "boostrap",
        'locale': 'fr',
        'plugins': [timeGridPlugin, interactionPlugin],
        'initialDate': FESTIVAL_START,
        'initialView': 'timeGridFourDay',
        'headerToolbar': false,
        'views': {
          'timeGridFourDay': {
            'type': 'timeGrid',
            'duration': { days: 4 },
            'allDaySlot': false,
          }
        },
        'height': 'auto',
        'nowIndicator': true,
        'eventBorderColor': '#000',
    }
    return (
        <FullCalendar
            { ...options } {...editable} events={events}
        />
    )
});

function eventClickOrNew(event, props) {
  props.setModalEvent(event);
  props.openModal(true);
}

function eventChangeAfterAlert(event, context, callback){
  if (window.confirm("Êtes-vous sûr de vouloir modifier ce shift?")) {
    eventChange(event, context, callback);
  };
}

export function eventChange(event, context, callback){
  let data = {
    id: event.id,
    debut: event.start,
    fin: event.end,
    effectif: event.effectif,
    coef: event.coef,
    benevoles: event.benevoles,
  }
  patch(context, data, API_SHIFTS_URL + data.id, {}, callback)
}

export function createEvent(event, polenom, poleid, formData, context, setSubmited, callback) {
  let data = {
    pole: { id: poleid, nom: polenom },
    debut: event.start,
    fin: event.end,
    effectif: formData.effectif,
    coef: formData.coef,
    benevoles: formData.benevoles,
  };
  post(context, data, API_SHIFTS_URL, {}, setSubmited, callback)
}

export function deleteEvent(event, context, callback) {
  if (window.confirm("Êtes-vous sûr de vouloir supprimer ce shift?")) {
    del(context, API_SHIFTS_URL + event.id, callback);
  }
}


function getEventOpts(showUsage, event) {
  let opts = [];
  // Couleur de l'event
  if (!showUsage) {
    // Couleur si page de bénévole
    opts.push("#52794f");
  } else if (event.benevoles.length < event.effectif) { 
    opts.push("#c01311"); 
  } else { 
    opts.push("#52794f");
  }
  // Titre de l'event
  if (!showUsage) {
    opts.push(`pole ${event.pole.nom}`);
  } else {
    const benevolesList = returnComaSeparatedList(event.benevoles);
    const listSeparator = benevolesList.length > 0 ? ":" : "";
    opts.push(`effectif ${event.benevoles.length}/${event.effectif} ${listSeparator} ${benevolesList}`);
  }
  return opts
}

const returnComaSeparatedList = (array) => array.map((b) => b.first_name + ' ' + b.last_name[0] + '.').join(", ")