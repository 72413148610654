import React, { useState } from 'react';
import { Input, Form, Button, Label} from "reactstrap";
import PropTypes from 'prop-types';
import { NEW_TOKEN_URL } from "../constants";
import { login } from "../includes/Requests";
import { notif } from '../includes/Notifications';

export default function Login({ setToken }) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async e  => {
    e.preventDefault();
    if (email === "" || password === "") {
      notif("error", "Oups!", "Veuillez remplir tous les champs")
    } else {
      const creds = {
        "email": email.toLowerCase(),
        "password": password,
      }
      login(setToken, NEW_TOKEN_URL, creds)
    }
  }

  return( 
    <div className="content center">
      <h1>J'ai un compte, je me connecte</h1>
      <Form onSubmit={handleSubmit}>
        <Label>
          <p>Adresse email</p>
          <Input type="email" onChange={e => setEmail(e.target.value)}/>
        </Label>
        <Label>
          <p>Mot de passe</p>
          <Input type="password" onChange={e => setPassword(e.target.value)}/>
        </Label>
        <Button type="submit">Se connecter</Button>
      </Form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};