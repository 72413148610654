import React, { useState } from 'react';
import { useUserContext } from "../components/UserContext";
import { Col, Input, Form, FormGroup, Button, Label } from "reactstrap";
import { CHANGE_PASSWD_APIURL } from "../constants";
import { patch } from "../includes/Requests";
import { checkPasswords } from "../components/InfosForm";
import { notif } from '../includes/Notifications';
export default function Passwd({ setInfos }) {
  const context = useUserContext();

  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [new_password2, setNewPassword2] = useState("");

  const handleSubmit = async e  => {
    e.preventDefault();
    const data = {
      "old_password": old_password,
      "new_password": new_password,
      "new_password2": new_password2,
    }
    if(!checkPasswords(data.new_password, data.new_password2)) {
      notif("error", "Problème de mot de passe", "Les deux mots de passe ne concordent pas");
      return
    }
    patch(context, data, CHANGE_PASSWD_APIURL, {})
  }

  return (
    <div>
      <h1>Changement de mot de passe</h1>
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Col sm={4}>
            <Label for="ancien-password">
              Ancien mot de passe
            </Label>
          </Col>
          <Col sm={8}>
            <Input id="ancien-password" type="password" onChange={e => setOldPassword(e.target.value)}/>
          </Col>
        <FormGroup row>
        </FormGroup>
          <Col sm={4}>
            <Label for="nouveau-password">
              Nouveau Mot de passe
            </Label>
          </Col>
          <Col sm={8}>
            <Input id="nouveau-password" type="password" onChange={e => setNewPassword(e.target.value)}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label for="nouveau-password2"> 
              Nouveau Mot de passe (encore)
            </Label>
          </Col>
          <Col sm={8}>
            <Input id="nouveau-password2" type="password" onChange={e => setNewPassword2(e.target.value)}/>
          </Col>
        </FormGroup>
          <Button type="submit" color="primary">Envoyer</Button>
      </Form>
    </div>
  )
}

/*Infos.propTypes = {
  setToken: PropTypes.func.isRequired
};*/