export const FESTIVAL_START = '2022-10-28';
export const FESTIVAL_START_TIME = "2022-10-28T12:00:00";

export const USER_URL = "/user/";
export const CHANGE_INFOS_URL = "/infos/";
export const CHANGE_PASSWD_URL = "/passwd/";

export var API_BASENAME;
if(process.env.NODE_ENV === "production") {
    API_BASENAME = "https://benevoles.blackwoodstock.nc";
} else {
    API_BASENAME = "http://127.0.0.1:8000";
}
export const API_USER_URL = API_BASENAME + "/api/user/";
export const API_ADHERENT_URL = API_BASENAME + "/api/adherent/";
export const API_BENEVOLES_URL = API_BASENAME + "/api/benevoles/";
export const API_BENEVOLES_ARRP_URL = API_BASENAME + "/api/benevoles/arrivee/";
export const API_BENEVOLES_POLE_URL = API_BASENAME + "/api/benevoles/pole/";
export const API_SHIFTS_URL = API_BASENAME + "/api/shifts/";
export const API_SHIFTS_POLE_URL = API_BASENAME + "/api/shifts/pole/";
export const API_POLES_URL = API_BASENAME + "/api/poles/";
export const API_FORMS_URL = API_BASENAME + "/api/forms/";
export const NEW_TOKEN_URL = API_BASENAME + "/api/token/";
export const API_REGISTRATION_URL = API_BASENAME + "/api/registration/";
export const CHANGE_INFOS_APIURL = API_BASENAME + "/api/infos/";
export const CHANGE_PASSWD_APIURL = API_BASENAME + "/api/passwd/";

export const primaryColor = "rgb(165, 59, 59)";

export const version="1.0.2";
