import axios from "axios";
import { notif } from '../includes/Notifications';

// GET
export async function get(setData, context, url, headers={}, stillLoading=null, callback=null) {
    let opts = getOpts(context, headers);
    try {
        let res = await axios.get(url, opts);
        stillLoading !== null && stillLoading(false);
        if (setData !== null) { 
          setData(res.data);
        }
    } catch (error) {
      stillLoading !== null && stillLoading(false);
      handleError(error, callback);
    }
}

export async function getToForm(setFormData, context, url, headers={}, stillLoading=null) {
    let opts = getOpts(context, headers);
    try {
        let res = await axios.get(url, opts);
        stillLoading !== null && stillLoading(false);
        Object.keys(res.data).forEach(k => setFormData({
          name: k,
          value: res.data[k],
        }))
    } catch (error) {
      stillLoading !== null && stillLoading(false);
      handleError(error);
    }
}

// PATCH
export async function patch(context, data, url, headers={}, callback=null) {
    let opts = getOpts(context, headers);
    try {
      await axios.patch(url, data, opts);
      notif("success", "Impeccable !", "Les données ont été mises à jour");
      if(callback !== null) {
        callback();
      }
    } catch (error) {
      handleError(error);
    }
}
// POST
export async function postRegistration(data, url, setSubmited=null)  {
    try {
      setSubmited && setSubmited(true);
      await axios.post(url, data);
      notif("success", "Parfait !", "Les données d'inscription ont bien été enregistrées");
    } catch (error) {
      setSubmited && setSubmited(false);
      handleError(error);
    }
}

export async function post(context, data, url, headers={}, setSubmited=null, callback=null)  {
    try {
      let opts = getOpts(context, headers);
      setSubmited && setSubmited(true);
      await axios.post(url, data, opts);
      setSubmited && setSubmited(false);
      notif("success", "Parfait !", "Données enregistrées");
      if(callback) {
        callback();
      }
    } catch (error) {
      setSubmited && setSubmited(false);
      handleError(error);
    }
}

// POST LOGIN
export async function login(setToken, url, creds) {
  try {
    let res = await axios.post(url, creds);
    setToken(res.data.access);
  } catch (error) {
    handleError(error);
  }
}

// DELETE
export async function del(context, url, callback) {
    try {
      let opts = getOpts(context, {});
      await axios.delete(url, opts);
      notif("success", "Et bim !", "Données supprimées");
      if(callback) {
        callback();
      }
    } catch(error) {
      handleError(error);
    }
}

function getOpts(context, headers) {
    let opts = { headers: headers};
    opts.headers.Authorization = `Bearer ${context.token}`;
    return opts
}

function handleError(error, callback) {
    console.log(error);
    if (error.code === "ERR_NETWORK") {
        notif("error","Problème de connexion", "L'application n'a plus accès à Internet ?");return;
    }
    if (error.response.status === 400) {
      const err = [];
      if (error.response.data !== "undefined") {
          Object.keys(error.response.data).forEach(key => {
            err.push(notif("error", `Erreur sur le champ ${key}`, error.response.data[key][0]))
          })
      }
      if(err.length !== 0) {
        err.forEach((err) => err);
      }
    }
    if (error.response.status === 401) {
        if (error.response.data.code === "token_not_valid") {
          notif("error","Oups!", "Token invalide, veuillez vous reconnecter");
          if(callback) {
            callback(null);
          }
          return;
        }
        notif("error","Oups!", "Identifiants incorrects !");return;
    } 
    if (error.response.status === 403) {
      notif("error","Erreur", error.response.data.detail);return;
    }
    if (error.response.status === 500) {
      if (error.message === "Network Error") {
        notif("error", "Erreur", "Problème d'accès réseau"); return;
      } else {
        notif("error", "Erreur", `Problème : ${error.message}`); return;
      }
    }
    if (error.response.data.error) {
        notif("error", "Erreur", error.response.data.error);return;
    } else {
        notif("error", "Erreur", "Erreur technique lors de la requête, veuillez réessayer");return;
    }
}
