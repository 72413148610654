import ReactLoading from 'react-loading';
import "./LoadingOverlay.css";
import { primaryColor } from '../constants';

export function LoadingOverlay(props) {
    const loadingProps = {
        type: "spinningBubbles",
        color: primaryColor,
        width: "150px",
        height: "100px",
        className: "loadingelement"
    }
    return (
    <div className="loadingoverlay">
        <ReactLoading {...loadingProps} /> 
    </div>

    )
}