import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { useUserContext } from "../components/UserContext";
import { API_BENEVOLES_URL, API_BENEVOLES_ARRP_URL, API_USER_URL, USER_URL, API_ADHERENT_URL } from "../constants";
import { get, post } from "../includes/Requests.js";
import { FaPole } from "./Pole";
import { FaCheck, FaWrench, FaUserClock, FaGem } from "react-icons/fa";
import { Multiselect } from "react-widgets";

export default function Benevoles() {
  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <AllBenevoles/>
        </Col>
      </Row>
    </Container>
  );
};

function AllBenevoles() {
 
  const [benevoles, setBenevoles] = useState([]);

  const context = useUserContext();

  const getBenevoles = useCallback(() => {
    get(setBenevoles, context, API_BENEVOLES_URL)
  }, [context])
  
  useEffect(() => {
   getBenevoles()
  }, [getBenevoles])

  context.getBenevoles = getBenevoles;

  return (
    <>
    <BenevolesStats allbenevoles= { benevoles }/>
    <Button onClick={getBenevoles}>Rafraîchir</Button>
    <BenevolesList allbenevoles={ benevoles } state="adherents" context={context}/>
    <BenevolesList allbenevoles={ benevoles } state="non-adherents" context={context}/>
    <Button onClick={getBenevoles}>Rafraîchir</Button>
    </>
  );
}

function BenevolesStats({ allbenevoles }) {
  return (
    <> 
      <p>
      Tous bénévoles et chefs de poles : { allbenevoles.length } <br />
      </p>
    </>
  )
}

function setAdherent(context, id) {
    post(context, {}, API_ADHERENT_URL + id , {}, null, context.getBenevoles)
}

function BenevolesList({ allbenevoles, state, context }) {
  const [modalOpened, setModalOpened] = useState(false); // État de la modal
  const [modalPole, setModalPole] = useState(); // Event de calendar actuellement selectionné pour la modal
  const toggle = () => setModalOpened(!modalOpened);

  let benevoles = [];
  state === "adherents" 
    ? benevoles = allbenevoles.filter(b => b.adherent) 
    : benevoles = allbenevoles.filter(b => ! b.adherent); 

  return (
    <>
    { benevoles && benevoles.length > 0 && (
    <div>
    <h1>Benevoles { state } <Badge color="primary">{benevoles.length}</Badge></h1>
    <Table responsive="md" className="benevoles" dark hover striped>
      <thead>
        <tr>
          { state === "non-adherents" && <th>Passer adhérent</th>}
          <th>Prénom/Nom</th>
          <th>Téléphone</th>
          <th>Pref 1</th>
          <th>Pref 2</th>
          { state === "adherents" && 
            <> 
            <th>Pole(s)</th> 
            <th>Heures travaillées (coef)</th> 
            {/*<th>Arrivée Pole</th>*/}
            </>
          } 
          <th>Commentaire</th>
        </tr>
      </thead>
      <tbody>
          { benevoles.map((benevole) => (
            <tr key={benevole.id}>
              { state === "non-adherents" && <td><FaCheck onClick={() => setAdherent(context, benevole.id)} style={{cursor:"pointer"}}/></td>}
              <td>{ benevole.mvb ? <FaGem/> : "" }<Link to={USER_URL + benevole.id}><span style={{textDecoration:"underline"}}>{benevole.first_name + " " + benevole.last_name}</span></Link></td>
              <td>{benevole.telephone}</td>
              <td>{benevole.preference_pole_1 && <FaPole nom={benevole.preference_pole_1.nom}/>}</td>
              <td>{benevole.preference_pole_2 && <FaPole nom={benevole.preference_pole_2.nom}/>}</td>
              { state === "adherents" && 
                <> 
                <td><PolesAssign benevole={benevole} setModalOpened={setModalOpened} setModalPole={setModalPole}/></td>
                <td>{benevole.heures_travaillees_coef && benevole.heures_travaillees_coef}</td>
                {/*<td><Arrivee benevole={benevole} context={context}/></td>*/}
                </>
              }
              <td className="comment">{benevole.commentaire && ( <ShowCommentaire benevole={benevole}/> )}</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
    <PoleModal
      modalOpened={modalOpened}
      setModalOpened={setModalOpened}
      toggle={toggle}
      modalPole={modalPole}
      setModalPole={setModalPole}
      context={context}
    />
    </div>
    )}
    </>
  )
}

function PolesAssign({benevole, setModalOpened, setModalPole}) {
  
  return (
    <>
      {benevole.poles && benevole.poles.map((pole) => <FaPole nom={pole.nom} key={pole.id} />) }
      <Button style={{margin:"0 0.5em", padding: "0 0.3em"}} onClick={() => {
        setModalPole(benevole);
        setModalOpened(true);
        }}>
       <FaWrench/>
      </Button>
    </>
  )
}

function PoleModal(props) {
  const [poles, setPoles] = useState([]);

  const multiselectFocus = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handleSubmit = () => {
    post(props.context, poles, API_USER_URL + props.modalPole.id + "/poles", {}, null, props.context.getBenevoles)
    props.setModalOpened(false);
  }
  return (
    <Modal
        fullscreen="md"
        isOpen={props.modalOpened}
        toggle={props.toggle}
        modalTransition={{ timeout: 0 }}
      >
        <ModalBody>
          <Label>
            Pôle assigné :
              <Multiselect
                    defaultValue={(props.modalPole && props.modalPole.poles) || []}
                    data={props.context.poles || []}
                    dataKey='id'
                    textField='nom'
                    placeholder="Pas de pôle assigné pour le moment"
                    onChange={value => setPoles((value))}
                    style={{marginBottom: "4em"}}
                    ref={multiselectFocus}
                    defaultOpen={true}/>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} >Modifier</Button>
          <Button onClick={props.toggle}>Annuler</Button>
        </ModalFooter>
      </Modal>
  )
}

export function ShowCommentaire({ benevole }) {
  const [ showModal, setShowModal ] = useState(false);

  return (
    <>
      <Button color="light" onClick={() => setShowModal(!showModal)}>
        Voir
      </Button>
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader>
          Commentaire de {benevole.first_name}
        </ModalHeader>
        <ModalBody>
          {benevole.commentaire}
        </ModalBody>
      </Modal>
    </>
  )
}

function Arrivee({benevole, context}) {

  const handleClick = () => {
    post(context, {}, API_BENEVOLES_ARRP_URL + benevole.id, {}, null, context.getBenevoles)
  }

  var date_db = benevole.date_arrivee_pole;
  if(date_db !== undefined && date_db !== null) {
    let date = new Date(date_db).toLocaleString("fr-FR", {"dateStyle": "short", "timeStyle": "short"});
    return <span>{date}</span>
  } else {
    return <FaUserClock onClick={handleClick} style={{cursor:"pointer"}} />
  }
}
