import { createContext, useContext } from "react";
import { useState, useEffect, useCallback } from "react";
import Login from "../pages/Login";
import Registration from "../pages/Registration";
import useToken from "./useToken";
import { get } from "../includes/Requests.js";
import { API_USER_URL, API_POLES_URL } from "../constants.js";
import { LoadingOverlay } from "./LoadingOverlay";

export function UserContextProvider(props) {
    const { token, setToken } = useToken(); // setToken == saveToken
    const [ poles, setPoles] = useState([]);
    const [ user, setUser] = useState({});

    const refreshUserData = useCallback(() => {
        if(token) {
            get(setUser, {"token": token}, API_USER_URL, {}, null, setToken);
            get(setPoles, {"token": token}, API_POLES_URL, {}, null, setToken);
        }
    // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        refreshUserData()
    }, [refreshUserData]);

    if(!token) {
        return (
            <NoToken setToken={setToken}/>
        ) 
    } 

    var context = {
        token: token,
        user: user,
        poles: poles,
        refreshUserData,
    };
    const isLoading = () => user.email === undefined && poles

    return (
        <UserContext.Provider value={context}>
            { isLoading() 
            ?
                <LoadingOverlay/>
            :    
                props.children
            }
        </UserContext.Provider>
    )
}

function NoToken(props) {
    return (
        <>
            <Login setToken={props.setToken}/>
            <hr />
            <Registration />
        </>
    )
}

const UserContext = createContext({});
export const useUserContext = () => useContext(UserContext)