
import { useState, useReducer } from "react";
//import DatePicker from "react-datepicker";
//import DatePicker from "react-widgets/DatePicker";
//import { DateLocalizer, NumberLocalizer } from 'react-widgets/IntlLocalizer';
//import { Localization} from "react-widgets";
import DateTimePicker from 'react-datetime-picker';

//import "react-datepicker/dist/react-datepicker.css";
import { Col, Input, Form, FormGroup, Button, Label, Alert } from "reactstrap";
import { postRegistration, patch } from "../includes/Requests";
import { API_REGISTRATION_URL, CHANGE_INFOS_APIURL, FESTIVAL_START_TIME } from "../constants";
import { notif } from "../includes/Notifications";
import ReCAPTCHA from "react-google-recaptcha";

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}

export const checkPasswords = (pass, pass2) => {
  if (pass === pass2) {
    return true
  }
  return false
}
export function InfosForm ({context, poles, isRegistration, children}) {
    //const [context, setContext] = useState({})
    //setContext(useUserContext());
    const [formData, setFormData] = useReducer(formReducer, context.user ? context.user : {})
    const [submited, setSubmitted] = useState(false);
    const [captcha, setCaptcha] = useState(null);


    const handleChange = event => {
      if (event.target.name !== "undefined" && event.target.value !== "undefined") {
        setFormData({
          name: event.target.name,
          value: event.target.value,
        });
      }
    }
    const handlePole = event => {
      if (event.target.name !== "undefined" && event.target.value !== "undefined") {
        setFormData({
          name: event.target.name,
          value: { id: event.target.value},
        });
      }
    }
    const handleCheckbox = event => {
      if (event.target.name !== "undefined" && event.target.checked !== "undefined") {
        setFormData({
          name: event.target.name,
          value: event.target.checked,
        });
      }
    }
    const handlePicker = value => {
      console.log(value);
      if (value !== null) {
        const date = new Date(value);
        setFormData({
           name: "date_disponible",
           value: date,
        });
      }
    }
    const mandatoryFields = ["first_name", "last_name", "email", "password", "telephone", "password2"]

    const handleSubmit = event => {
      event.preventDefault();
      if (!isRegistration) {
        return patch(context, formData, CHANGE_INFOS_APIURL, {}, context.refreshUserData)
      }
      const err=[]
      mandatoryFields.forEach((key) => {
        if(!formData.hasOwnProperty(key) || formData[key] === "") {
          err.push(notif("error", "Champ obligatoire", `Le champ ${key} est nécessaire`))
        }
      })
      if (!checkPasswords(formData.password, formData.password2)) {
        err.push(notif("error", "Problème de mot de passe", "Les deux mots de passe ne concordent pas"));
      }
      if (captcha === null) {
        err.push(notif("error", "Problème de captcha", "Êtes vous un robot ? Ça nous serait pas super utile sur le festival !"));
      }
      if(err.length !== 0) {
        err.forEach((err) => err);
        return
      }
      postRegistration(formData, API_REGISTRATION_URL, setSubmitted);
    }

  return (
    <div>
        {children}
        <Form>
          {isRegistration && 
          <>
          <FormGroup row>
            <Label for="prenom" sm={3}>Prénom*: </Label>
            <Col sm={9}>
              <Input id="prenom" name="first_name" onChange={handleChange} value={formData.first_name || ''}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="nom" sm={3}>Nom*: </Label>
            <Col sm={9}>
              <Input id="nom" name="last_name" onChange={handleChange} value={formData.last_name || ''}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={3}>Adresse email*: </Label>
            <Col sm={9}>
              <Input id="email" name="email" type="email" onChange={handleChange} value={formData.email || ''}/>
            </Col>
          </FormGroup>
          </>
          }
          <FormGroup row>
            <Label for="telephone" sm={3}>Telephone*: </Label>
            <Col sm={9}>
              <Input id="telephone" name="telephone" onChange={handleChange} value={formData.telephone || ''}/>
            </Col>
          </FormGroup>
          {isRegistration && 
            <FormGroup row>
            <Label for="password" sm={3}> Mot de passe*: </Label>
            <Col sm={9}>
              <Input id="password" type="password" name="password" onChange={handleChange} value={formData.password || ''}/>
            </Col>
            <Label for="password2" sm={3}> Encore*:</Label>
            <Col sm={9}>
              <Input id="password2" type="password" name="password2" onChange={handleChange} value={formData.password2 || ''}/>
            </Col>
          </FormGroup>
          }
          <FormGroup row>
            <Label for="vegan" sm={3}>Repas Vegan ? : </Label>
            <Col sm={9}>
              <Input id="vegan" type="checkbox" name="vegan" onChange={handleCheckbox} value={formData.vegan || ''}/>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="tshirt" sm={3}>Taille de t-shirt: </Label>
            <Col sm={9}>
              <Input id="tshirt" type="select" name="tshirt" onChange={handleChange} value={formData.tshirt || 'l'}>
                <option value="s">S</option>
                <option value="m">M</option>
                <option value="l">L</option>
                <option value="xl">XL</option>
              </Input>
            </Col>
          </FormGroup>
            <p>Quelques petits détails: 
              <ul>
                <li>
                  Le pôle <strong>Déco</strong> vous demandera d'être présent sur site un ou deux jours avant l'ouverture, mais vous permettra de ne pas avoir à faire de créneaux pendant le festival. 
                </li>
                <li>
                  Globalement, les heures de travail tôt en matinée ou tard après minuit seront surévaluées par rapport aux autres pour raisons de pénibilité.
                </li>
                <li>
                  Par exemple, le pôle <strong>P'tits Dej</strong> n'est pas pour les lève-tard mais demandera moins d'heures de travail au total, idem pour la buvette de nuit.
                </li>
              </ul>
            </p>
          <FormGroup row>
            <Label for="pref1" sm={3}>Préference de pole 1: </Label>
            <Col sm={9}>
              <Input id="pref1" type="select" name="preference_pole_1" onChange={handlePole} defaultValue={formData.preference_pole_1 ? formData.preference_pole_1.id : ""}>
                <option value="">---</option>
                {poles.map((p, k) => p.preference && <option value={p.id} key={k}>{p.nom}</option>)}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="pref2" sm={3}>Préference de pole 2:
            </Label>
            <Col sm={9}>
              <Input id="pref2" type="select" name="preference_pole_2" onChange={handlePole} defaultValue={formData.preference_pole_2 ? formData.preference_pole_2.id : ""}>
                <option value="">---</option>
                {poles.map((p, k) => p.preference && <option value={p.id} key={k}>{p.nom}</option>)}
              </Input>
            </Col>
          </FormGroup>
            <p>
              Si vous ne pouvez pas arriver avant 14h le vendredi 28 octobre, essayez d'estimer (sans abus) votre heure d'arrivée sur les lieux du festival (utiliser les touches flechées si besoin).
            </p>
          <FormGroup row>
            <Label for="date_dispo" sm={3}>Date de disponibilité sur le fest:
            </Label>
            <Col sm={9}>
                <DateTimePicker value={(formData.date_disponible && new Date(formData.date_disponible)) || new Date(FESTIVAL_START_TIME)} onChange={handlePicker} clearIcon={null}/>
            </ Col>
          </FormGroup>
          <FormGroup row>
            <Label for="commentaire" sm={3}>Un commentaire ?
            </Label>
            <Col sm={9}>
              <Input id="commentaire" type="textarea" name="commentaire" onChange={handleChange} onSelect={(d) => console.log(d) } value={formData.commentaire || ''}/>
            </Col>
          </FormGroup>
          { isRegistration && 
          <>
            <span>*: champs obligatoires</span>
            <ReCAPTCHA
              sitekey="6LeeW2whAAAAAG8khR5_d3YVVaudNY2E5Byo5HO8"
              onChange={(v) => setCaptcha(v)}
            />
          </>
          }
          <Submit handleSubmit={handleSubmit} submited={submited} isRegistration={isRegistration}/>
        </Form>
    </div>
    )
}

function Submit({ handleSubmit, submited, isRegistration }) {
    const Registered = () => <Alert>
          Merci pour votre enregistrement en tant que bénévole sur notre Festival ! Cet enregistrement vous garantit un accès au festival mais vous engage aussi à venir nous aider.<br/>
          Vous pourrez bientôt revenir pour prendre connaissance de vos poles d'affectation et de vos shifts. Surveillez vos dossiers spam qui peuvent être méchants avec nos emails !
        </Alert>
      
    const ButtonTxt = isRegistration ? "S'enregistrer" : "Envoyer"
  if (!submited) {
    return <Button type="submit" onClick={handleSubmit} color="primary">{ButtonTxt}</Button>
  } else {
    return (
      <>
        <Button type="submit" disabled>{ButtonTxt}</Button>
        {isRegistration && Registered()}
      </>
    )
  }
}