import { useState, useEffect } from "react";
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from "reactstrap";
import { Link } from "react-router-dom";
import { useUserContext } from "../components/UserContext.js";
import { FaDoorOpen, FaUserCircle } from 'react-icons/fa';
import { FaPole } from '../pages/Pole';


export default function Header () {
    const context = useUserContext();
    const [chefOfPole, setChefOfPole] = useState([]);
    const [collapseOpen, setCollapseOpen] = useState(false);
    const toggleNav = () => setCollapseOpen(!collapseOpen);
    const collapseIfOpened = () => { 
        collapseOpen && setCollapseOpen(false);
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        // Si admin, alors chef de poles de tous les pôles, sinon seulement des pôles assignés
        if (context.user.is_staff) {
            setChefOfPole(context.poles);
        } else {
            var chefOfPoleTemp = [];
            context.poles.forEach((p) => {
                p.chefs.forEach((c) => context.user.id === c.id && chefOfPoleTemp.push(p))
            })
            if(chefOfPoleTemp.length > 0) {
                setChefOfPole(chefOfPoleTemp);
            }
        }
    }, [context.poles, context.user.id, context.user.is_staff])
    return (
    <div id="navigation">
        <Navbar
            color="primary"
            dark
            expand="md"
            fixed="top"
        >
            <NavbarBrand tag="span">
               <Link to="user" className="nav-link" onClick={collapseIfOpened}>
                    <FaUserCircle /> {`${context.user.first_name} ${context.user.last_name}`}
               </Link>
            </NavbarBrand>

            <NavbarToggler onClick={toggleNav} />
            <Collapse isOpen={collapseOpen} navbar>
                <Nav
                    className="me-auto"
                    navbar
                    >
                    { context.user.is_staff && (
                    <>
                    <NavItem>
                            <Link to="gestion-benevoles" className="nav-link" onClick={collapseIfOpened}>
                                Gestion des bénévoles
                            </Link>
                    </NavItem>
                    </>
                    ) }

                    { chefOfPole && chefOfPole.length > 0 && (
                    <UncontrolledDropdown 
                        inNavbar
                        nav>
                        <DropdownToggle
                            caret
                            nav
                            >
                            Gestion des pôles
                        </DropdownToggle>
                        <DropdownMenu end>
                            {chefOfPole.map(pole => (
                                    pole.gestion &&
                                    <DropdownItem key={pole.id}>
                                    <Link to={`pole/${pole.id}`} className="nav-link" onClick={collapseIfOpened}>
                                        <FaPole nom={pole.nom}/>  { pole.nom }
                                    </Link>
                                    </DropdownItem>
                            ))
                            
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    
                    ) }
                    { context.user.adherent &&
                    <NavItem>
                        <Link to="faq" className="nav-link" onClick={collapseIfOpened}>
                            Infos Pratiques
                        </Link>
                    </NavItem>
                    }
                </Nav>
                <NavbarText>
                    <NavLink href="/" onClick={() => { localStorage.removeItem("token") }}>
                        <FaDoorOpen/> Se déconnecter
                    </NavLink>
                </NavbarText>
            </Collapse>
        </Navbar>
    </div>

    )
}