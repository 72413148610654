import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import { Profile } from "./pages/Profile";
import Benevoles from "./pages/Benevoles";
import Pole from "./pages/Pole";
import Infos from "./pages/Infos";
import FAQ from "./pages/FAQ";
import Passwd from "./pages/Passwd";
import Footer from "./components/Footer";
import { UserContextProvider } from "./components/UserContext";


export default function App () {
    
  return (
  <>
  <UserContextProvider>
    <Router>
      <Header />
      <div className="content">
      <Routes>
        <Route exact path="/" element={<Navigate to="/user/" replace/>}/>
        <Route path="/user/" element={<Profile />}/>
        <Route path="/user/:userid" element={<Profile />}/>
        <Route path="/gestion-benevoles/" element={<Benevoles />}/>
        <Route path="/pole/:id" element={<Pole />}/>
        <Route path="/infos/" element={<Infos />}/>
        <Route path="/faq/" element={<FAQ />}/>
        <Route path="/passwd/" element={<Passwd />}/>
      </Routes>
      </div>
      <Footer />
    </Router>
  </UserContextProvider>
  </>

  )
}
