import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button, Badge, Tooltip,   UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem, Table } from "reactstrap";
import { useUserContext } from "../components/UserContext";
import { get } from "../includes/Requests";
import CalendarWithModal from "../components/Calendar";
import { API_SHIFTS_POLE_URL, API_BENEVOLES_POLE_URL } from "../constants";
import { FaCoffee, FaCoins, FaBeer, FaGlassMartiniAlt, FaGuitar, FaUserNurse, FaCamera, FaInfoCircle, FaGem, FaMapSigns, FaIcons } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { USER_URL } from "../constants";
import { ShowCommentaire } from "./Benevoles";

const poleIcons = {
  // Ces noms doivent être ceux des noms de Pôle présents dans la base. Les valeurs sont les objets Icons à renvoyer
  "Buvette": FaBeer,
  "Buvette Premium": FaGlassMartiniAlt,
  "Jetons": FaCoins,
  "P'tits Dej": FaCoffee,
  "Backstage": FaGuitar,
  "Potographes": FaCamera,
  "Santé": FaUserNurse,
  "Merch/Benevoles": FaInfoCircle,
  "Déco": FaMapSigns,
  "Autres": FaIcons,
}


export default function Pole() {
    const context = useUserContext();
    const [shifts, setShifts] = useState([]);
    const [benevoles, setBenevoles] = useState([]);

    const { id } = useParams();
    const pole = context.poles.filter(pole => pole.id === parseInt(id))[0];

    const getBenevoles = useCallback(() => {
      if (pole && pole.id !== undefined) {
        get(setBenevoles, context, API_BENEVOLES_POLE_URL + pole.id)
      }
    }, [context, pole])

  
    const getEvents = useCallback(() => {
      get(setShifts, context, API_SHIFTS_POLE_URL + id)
    }, [context, id])
  
    const getAll = useCallback(() => {
      getEvents();
      getBenevoles();
    }, [getEvents, getBenevoles])
  
    useEffect(() => {
      getAll()
    }, [getAll])


    var stats = {};
    const getStats = () => {
      if (!pole || !shifts) { return {} }
      stats.chefsCount = pole.chefs.length;
      stats.chefsList = pole.chefs.map((c) => c.first_name).join(', ')
      stats.benevolesCount = benevoles.length;
      if (shifts.length !== 0) {
        stats.totalHeures = shifts.map((s) => s.duree_heures).reduce((p, c) => p + c);
        stats.benevolesCount > 0 ? stats.heuresParBenevole = (stats.totalHeures / stats.benevolesCount).toFixed(2) : stats.heuresParBenevole = null;
      }
      return stats;
      
    }

    stats = getStats();

    return (
    <Container>
      <Row>
        <Col>
          { pole &&
            <h1><FaPole nom={pole.nom}/> Pole {pole.nom} <Badge color="primary">{stats.benevolesCount}</Badge></h1>
          }
        </Col>
        <Col style={{ textAlign:"right"}}>
          <Button onClick={getAll}>Rafraîchir</Button>
        </Col>
        
      </Row>
      <Row>
        <Col>
          {stats.chefsCount ? <span>Chefs assignés ({stats.chefsCount}) : {stats.chefsList}<br/></span> : '' }
          {stats.totalHeures ? <span>Nombre total d'heures définies: {stats.totalHeures}<br/></span> : ''}
          {stats.heuresParBenevole ? <span>Nombre moyen d'heures par bénévole: {stats.heuresParBenevole}<br/></span> : ''}
        </Col>
      </Row>
      <BenevoleStats benevoles={benevoles}/>
      <Card
          body
          inverse
          style={{
              backgroundColor: '#333',
              borderColor: '#333',
            }}
      > 
        <CalendarWithModal pole={pole} benevoles={benevoles} events={shifts} getAll={getAll}/>
      </Card>
      <Button onClick={getAll}>Rafraîchir</Button>
    </Container>
    );
};

function BenevoleStats({benevoles}) {

  /*let poleBenevolesStats = [];
  poleBenevolesStats = benevoles.map((b) => {
    return {
      id: b.id,
      first_name: b.first_name || "",
      last_name: b.last_name || "",
      telephone: b.telephone,
      mvb: b.mvb,
      heures: b.heures_travaillees,
      heures_coef: b.heures_travaillees_coef,
      commentaire: b.commentaire,
    };
  })
  poleBenevolesStats = benevoles.map((b) => {
    let bStats = {
      id: b.id,
      first_name: b.first_name || "",
      last_name: b.last_name || "",
    };
    benevoles.forEach((v) => {
      if (v.id === bStats.id) {
        bStats.telephone = v.telephone;
        bStats.mvb = v.mvb;
        bStats.heures = v.heures_travaillees;
        bStats.heures_coef = v.heures_travaillees_coef;
        bStats.commentaire = v.commentaire;
      }
    })
    return bStats;
  })*/
  //.sort((b1, b2) => b1.heures_coef - b2.heures_coef)


  return (
    <Row>
    <UncontrolledAccordion defaultOpen="0">
      <AccordionItem>
        <AccordionHeader targetId="1">
          Stats des bénévoles
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <Table responsive="md" className="benevoles" dark hover striped>
            <thead>
              <tr>
                <th>Prénom/Nom</th>
                <th>Téléphone</th>
                <th>Date disponibilité</th>
                <th>Heures tot.</th>
                <th>Heures tot. (coef)</th>
                <th>Commentaire</th>
              </tr>
            </thead>
            <tbody>
                { benevoles.map((benevole) => {
                  let date_dispo = new Date(benevole.date_disponible).toLocaleString("fr-FR", {"dateStyle": "short", "timeStyle": "short"});
                  return (

                    <tr key={benevole.id}>
                      <td>{ benevole.mvb ? <FaGem/> : "" }<Link to={USER_URL + benevole.id}><span style={{textDecoration:"underline"}}>{benevole.first_name} {benevole.last_name}</span></Link></td>
                      <td>{benevole.telephone}</td>
                      <td>{date_dispo}</td>
                      <td>{benevole.heures_travaillees}</td>
                      <td>{benevole.heures_travaillees_coef}</td>
                      <td className="comment">{benevole.commentaire && ( <ShowCommentaire benevole={benevole}/> )}</td>
                    </tr>
                  )
                  })
              }
            </tbody>
          </Table>
        </AccordionBody>
      </AccordionItem>
    </UncontrolledAccordion>
    </Row>
  )

}

export function FaPole({nom}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  if (Object.keys(poleIcons).indexOf(nom) !== -1 ) {
    var id=Object.keys(poleIcons).indexOf(nom);
    var Pole = poleIcons[nom];
    var tooltipName = "tooltip-" + parseInt(id);
    return (
      <>
      <span id={tooltipName}><Pole/></span>
      <Tooltip 
        autohide={true}
        flip={true}
        isOpen={tooltipOpen}
        target={tooltipName}
        toggle={toggle}>
          {nom}
        </Tooltip>
      </>
    )
  } else return <></>
}
